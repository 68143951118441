<template>
  <div style="position: relative">
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            7. Пожаровзрывоопасность
          </v-card-title>
          <v-card-text class="title font-weight-light">
              <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-img
                    lazy-src="img/material/fire.svg"
                    max-width="100"
                    src="img/material/fire.svg"
                    class="ma-1"
                  ></v-img>
                  <v-spacer></v-spacer>
                  <v-card-text class="title font-weight-light">
                    <p><span class="red--text font-weight-bold">Пожаровзрывоопасность </span>- совокупность свойств, характеризующих способность веществ и материалов к возникновению и распространению горения. Следствием горения могут быть пожар или взрыв.</p>
                  </v-card-text>
                </v-card>
          </v-card-text >
          <v-card-title>
            Показатели пожаровзрывоопасности согласно ГОСТ 12.1.044-89:
          </v-card-title>
        </v-col>
        <v-col cols="6">
            <v-tabs v-model="tab" grow vertical  color="red">
              <v-tab class="justify-start">Температура вспышки</v-tab>
              <v-tab class="justify-start">Температура воспламенения</v-tab>
              <v-tab class="justify-start">Температура самовоспламенения</v-tab>
              <v-tab class="justify-start">Концентрационные пределы распространения пламени</v-tab>
              <v-tab class="justify-start">Температурные пределы распространения пламени</v-tab>
            </v-tabs>
            <Hint></Hint>
        </v-col>
        <v-col cols="6">
          <v-tabs-items vertical v-model="tab">
            <v-tab-item>
              <v-card-title>
                Температура вспышки:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                наименьшая температура, при которой над поверхностью вещества образуются пары, способные вспыхивать в воздухе от источников зажигания. Устойчивое горение не возникает.
              </v-card-text>
              <v-card-title>
                Вспышка:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                быстрое сгорание газопаровоздушной смеси над поверхностью горючего вещества, сопровождающееся кратковременным видимым свечением.
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-title>
                Температура воспламенения:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                наименьшая температура, при которой вещество выделяет горючие пары и газы с такой скоростью, что при воздействии источника зажигания возникает воспламенение.
              </v-card-text>
              <v-card-title>
                Воспламенение:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                пламенное горение вещества, инициированное источником зажигания и продолжающееся после его удаления.
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-title>
                Температура самовоспламенения:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                наименьшая температура окружающей среды, при которой возникает самовоспламенение вещества.
              </v-card-text>
              <v-card-title>
                Самовоспламенение:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                резкое увеличение скорости экзотермических объемных реакций, сопровождающееся пламенным горением и/или взрывом.
              </v-card-text>

            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                Нижний (верхний) концентрационный предел распространения пламени:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                минимальное (максимальное) содержание горючего вещества в однородной смеси с окислительной средой, при котором возможно распространение пламени по смеси на любое расстояние от источника зажигания.
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-title>
                Температурные пределы распространения пламени:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                температуры, при которых насыщенный пар вещества образует в окислительной среде концентрации, равные соответственно нижнему и верхнему концентрационным пределам распространения пламени.
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!-- <v-col cols="12">
          <Hint></Hint>
        </v-col> -->
        <v-col>
          <v-card-text class="title">
            Также важно учитывать скорость и интенсивность испарения ГЖ, ЛВЖ со свободной поверхности при открытых резервуарах, емкостях, корпусах технологических установок.
          </v-card-text>
        </v-col>
      <!-- <span class="ptm-bg-img"></span> -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-fire-img {
  position: absolute;
  top: 0;
  right: 5%;
}
</style>
